<template>
  <div>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Ajout de fichier(s) : <v-icon
          class="mx-4"
        >
          {{ mdiFolderOpen }}
        </v-icon>{{ selectedFolder.name }}
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-spacer></v-spacer>

        <v-file-input
          v-model="newFiles"
          chips
          multiple
          label="Ajouter des fichiers"
        ></v-file-input>

        <v-btn
          color="primary"
          :disabled="newFiles.length <= 0"
          class="me-3 mt-4"
          @click.prevent="uploadFiles"
        >
          Ok
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    selectedFolder: {
      type: Object,
      default: () => { },
    },
    mdiFolderOpen: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      newFiles: [],
      returnDir: {},
    }
  },
  methods: {
    uploadFiles() {
      const formData = new FormData()
      let i = 0
      this.newFiles.forEach(file => {
        formData.append('files', file, file.name)
        i += 1
      })
      axios.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
        .then(response => {
          this.returnDir.files = this.selectedFolder.files
          response.data.forEach(fichier => {
            this.returnDir.files.push(fichier)
          })
          axios.put(`${process.env.VUE_APP_API_URL}/folders/${this.selectedFolder.id}`, { files: this.returnDir.files })
            .then(response2 => {
              this.$emit('newContent', response2.data)
              this.$store.commit('snackMe', {
                color: 'success',
                text: `${i} Fichier(s) ajouté(s)`,
                value: true,
              })
              this.newFiles = []
            })
            .catch(error => {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de l\'ajout des fichiers',
                value: true,
              })
              console.log(error)
            })
        })
        .catch(error => console.error(error))
    },
  },
}
</script>

<style>

</style>
