<template>
  <v-row
    class="justify-center text-center"
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="folder"
        label="Nouveau dossier"
        dense
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-btn
        color="success"
        outlined
        @click="createFolder"
      >
        Créer le dossier
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      folder: '',
    }
  },
  methods: {
    createFolder() {
      axios.post(`${process.env.VUE_APP_API_URL}/folders`, { name: this.folder })
        .then(res => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: `Dossier ${res.data.name} créé`,
            value: true,
          })
          this.$emit('newFolder', res.data)
          this.folder = ''
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création du dossier',
            value: true,
          })
          console.log(err)
        })
    },
  },
}
</script>

<style>

</style>
