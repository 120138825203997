<template>
  <v-card>
    <v-card-title>
      <v-row class="justify-center">
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="search"
            label="Rechercher"
            hide-details="auto"
            :append-icon="icons.mdiMagnify"
            clearable
            :clear-icon="icons.mdiCloseCircleOutline"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <FichiersTreeVue :search="search"></FichiersTreeVue>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import {
  mdiMagnify, mdiFilePdfBox, mdiFileOutline, mdiCloseCircleOutline,
} from '@mdi/js'
import FichiersTreeVue from './FichiersTree.vue'

export default {
  components: {
    FichiersTreeVue,
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiFilePdfBox,
        mdiFileOutline,
        mdiCloseCircleOutline,
      },
    }
  },
  data() {
    return {
      directory: {
        files: [
          {
            name: '',
          },
        ],
      },
      search: '',
    }
  },
  computed: {
    filterFichiers() {
      return this.directory.files.filter(e => e.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  mounted() {

  },
  methods: {
    fetchData() {
    },
    linkToFile(a) {
      return `${process.env.VUE_APP_API_URL + a}`
    },
    removeMedia(id) {
      const fileDeleted = this.directory.files.filter(obj => obj.id !== id)
      axios.put(`${process.env.VUE_APP_API_URL}/directory`, { files: fileDeleted })
        .then(response => {
          this.directory.files = response.data.files
          axios.delete(`${process.env.VUE_APP_API_URL}/upload/files/${id}`)
            .then(() => {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Fichier supprimé',
                value: true,
              })
            })
            .catch(err => {
              console.log(err)
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de la suppression du fichier',
                value: true,
              })
            })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du fichier',
            value: true,
          })
          console.log(error)
        })
    },

  },
}
</script>

<style>

</style>
