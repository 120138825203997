<template>
  <div>
    <v-treeview
      v-model="tree"
      :items="directory"
      :search="search"
      :filter="filter"
      activatable
      item-children="files"
      item-key="name"
      open-on-click
      class="mb-6"
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon
          v-if="!item.size"
          class="py-3"
        >
          {{ open ? icons.mdiFolderOpen : icons.mdiFolder }}
        </v-icon>
        <v-icon
          v-else
          class="py-3"
        >
          {{ getIcon(item) }}
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <div v-if="item.size">
          <b>{{ item.name }}</b> [Ajouté le {{ new Date(item.updated_at) | date('dd/MM/yyyy à HH:mm') }}]
        </div>
        <div v-else>
          {{ item.name }}
        </div>
      </template>
      <template v-slot:append="{ item }">
        <div v-if="!item.size">
          <v-icon
            class="mx-4"
            @click="warningDelete(item)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
          <v-icon
            class="pr-6"
            @click="uploadFile(item)"
          >
            {{ icons.mdiFileUploadOutline }}
          </v-icon>
        </div>
        <div v-else>
          <v-icon
            class="mx-4"
            @click="deleteMedia(item)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
          <v-icon
            class="mx-4"
            @click="downloadFile(item)"
          >
            {{ icons.mdiDownload }}
          </v-icon>
          <v-icon
            @click="showFile(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </div>
      </template>
    </v-treeview>
    <v-spacer>
    </v-spacer>
    <FichiersTreeAddFolderVue @newFolder="appendFolder"></FichiersTreeAddFolderVue>
    <v-dialog
      v-model="uploadDialog"
      width="700"
    >
      <FichiersUploadDialogVue
        :selected-folder="selectedFolder"
        :mdi-folder-open="icons.mdiFolderOpen"
        @newContent="appendFiles"
      ></FichiersUploadDialogVue>
    </v-dialog>
    <div class="text-center">
      <v-dialog
        v-model="dialogDelete"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Attention
          </v-card-title>

          <v-card-text>
            Vous allez supprimer le dossier {{ selected.name }} ainsi que le(s) {{ selected.files.length }} fichier(s) qu'il contient, êtes-vous sur de vouloir continuer ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="deleteFolder(selected)"
            >
              Supprimer le dossier
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  mdiFolderOpen,
  mdiFolder,
  mdiFile,
  mdiFilePdfBox,
  mdiFileImage,
  mdiFileExcel,
  mdiFileWord,
  mdiDownload,
  mdiFileUploadOutline,
  mdiDelete,
  mdiEye,
} from '@mdi/js'
import axios from 'axios'
import FichiersTreeAddFolderVue from './FichiersTreeAddFolder.vue'
import FichiersUploadDialogVue from './FichiersUploadDialog.vue'

export default {
  components: {
    FichiersTreeAddFolderVue,
    FichiersUploadDialogVue,
  },
  props: {
    search: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      directory: [],
      tree: [],
      uploadDialog: false,
      selectedFolder: {},
      dialogDelete: false,
      selected: {
        name: '',
        files: [],
      },
    }
  },
  setup() {
    return {
      icons: {
        mdiFolderOpen,
        mdiFolder,
        mdiFile,
        mdiFilePdfBox,
        mdiFileImage,
        mdiFileExcel,
        mdiFileWord,
        mdiDownload,
        mdiFileUploadOutline,
        mdiDelete,
        mdiEye,
      },
    }
  },
  computed: {
    filter() {
      // eslint-disable-next-line no-constant-condition
      return false
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  mounted() {

  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_API_URL}/folders`)
        .then(dir => {
          this.directory = dir.data
        })
        .catch(error => console.error(error))
    },
    warningDelete(a) {
      this.dialogDelete = true
      this.selected = a
    },
    uploadFile(a) {
      this.uploadDialog = true
      this.selectedFolder = a
    },
    downloadFile(a) {
      const dl = document.createElement('a')
      dl.href = `${process.env.VUE_APP_API_URL + a.url}`
      dl.download = a.name
      document.body.appendChild(dl)
      dl.click()
      document.body.removeChild(dl)
    },
    showFile(a) {
      window.open(`${process.env.VUE_APP_API_URL + a.url}`)
    },
    appendFolder(e) {
      this.directory.push(e)
    },
    appendFiles() {
      this.uploadDialog = false
    },
    deleteFolder(e) {
      e.files.forEach(file => {
        this.deleteFile(file.id)
      })
      axios.delete(`${process.env.VUE_APP_API_URL}/folders/${e.id}`)
        .then(() => {
          this.directory = this.directory.filter(folders => folders.id !== e.id)
          this.$store.commit('snackMe', {
            color: 'info',
            text: `Dossier ${e.name} supprimé`,
            value: true,
          })
        })
        .catch(error => console.error(error))
      this.dialogDelete = false
    },
    deleteMedia(item) {
      let matchingFolder = {}
      this.directory.forEach(dir => {
        // eslint-disable-next-line no-return-assign
        if (dir.files.some(file => file.id === item.id)) {
          matchingFolder = dir
        }

        return matchingFolder
      })

      const fileDeleted = matchingFolder.files.filter(obj => obj.id !== item.id)
      axios.put(`${process.env.VUE_APP_API_URL}/folders/${matchingFolder.id}`, { files: fileDeleted })
        .then(response => {
          matchingFolder.files = response.data.files
          const findIndex = this.directory.findIndex(folder => folder.id === matchingFolder.id)
          this.directory[findIndex] = matchingFolder
          this.deleteFile(item.id)
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du fichier',
            value: true,
          })
          console.log(error)
        })
    },
    deleteFile(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}/upload/files/${id}`)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Fichier supprimé',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du fichier',
            value: true,
          })
        })
    },

    getIcon(file) {
      switch (file.ext) {
        case '.jpg':
        case '.png':
        case '.jpeg':
          return this.icons.mdiFileImage
        case '.pdf':
          return this.icons.mdiFilePdfBox
        case '.xls':
        case '.xlsx':
          return this.icons.mdiFileExcel
        case '.doc':
        case '.docx':
          return this.icons.mdiFileWord
        default:
          return this.icons.mdiFile
      }
    },
  },

}
</script>

<style scoped>
</style>
